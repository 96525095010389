<template>
  <v-form v-model="formValid">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="titleBefore"
            :label="$t('label.titleBefore')"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="firstName"
            :label="$t('label.firstName')"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="lastName"
            :label="$t('label.lastName')"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field v-model="titleAfter" :label="$t('label.titleAfter')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="32"
            :nudge-top="16"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="birthDate"
                :label="$t('label.birthDate')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              @input="dateMenu = false"
              :allowed-dates="(val) => val <= new Date().toISOString()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="bornLastName"
            :label="$t('label.bornLastName')"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="regNum"
            :label="$t('label.regNum')"
            :rules="[rules.integer]"
            :error-messages="errors.reg_number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-textarea
            v-model="publicNotes"
            :label="$t('label.publicNotes')"
            rows="3"
            filled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="can('manage_internal_notes', 'person')" class="py-0">
          <v-textarea
            v-model="internalNotes"
            :label="$t('label.internalNotes')"
            rows="3"
            filled
          />
        </v-col>
      </v-row>

      <AddressEntry :address.sync="address" />

      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="save()" color="primary" :disabled="!formValid">{{
            $t("button.save")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import FormValidationMixin from "@/components/mixins/FormValidationMixin";
import { mapActions, mapGetters } from "vuex";
import AddressEntry from "@/components/forms/AddressEntry";

export default {
  name: "EditPersonWidget",
  components: { AddressEntry },
  mixins: [FormValidationMixin],

  props: {
    person: { required: false, type: Object },
  },

  data() {
    return {
      obj: this.person,
      firstName: this.person ? this.person.first_name : "",
      lastName: this.person ? this.person.last_name : "",
      titleBefore: this.person ? this.person.title_before : "",
      titleAfter: this.person ? this.person.title_after : "",
      birthDate: this.person ? this.person.birth_date : null,
      dateMenu: false,
      formValid: false,
      internalNotes: this.person ? this.person.internal_notes : "",
      publicNotes: this.person ? this.person.public_notes : "",
      bornLastName: this.person ? this.person.born_last_name : "",
      address: this.person ? { ...this.person.address } : {},
      regNum: this.person ? this.person.reg_number : "",
      errors: {},
    };
  },

  computed: {
    ...mapGetters(["can"]),
  },

  methods: {
    ...mapActions(["showMessage"]),
    async save() {
      let data = {
        first_name: this.firstName,
        last_name: this.lastName,
        title_before: this.titleBefore,
        title_after: this.titleAfter,
        birth_date: this.birthDate,
        internal_notes: this.internalNotes,
        public_notes: this.publicNotes,
        born_last_name: this.bornLastName,
        address: this.address,
        reg_number: this.regNum || null,
      };
      try {
        let resp = null;
        if (this.obj) {
          resp = await axios.patch(`/api/person/${this.obj.pk}/`, data);
        } else {
          resp = await axios.post("/api/person/", data);
        }
        this.$emit("save", resp.data);
      } catch (error) {
        this.showMessage({
          text: this.$t("error.personSaveFailed"),
          color: "error",
        });
        this.errors = error.response.data;
        console.error("Error saving data: ", error);
      }
    },
  },

  watch: {
    regNum() {
      this.errors.reg_number = [];
    },
  },
};
</script>
