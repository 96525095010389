<template>
  <v-form v-model="formValid">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="name"
            :label="$t('label.organizationName')"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="regNum"
            :label="$t('label.regNum')"
            :rules="[rules.integer]"
          />
        </v-col>
      </v-row>
      <AddressEntry :address.sync="address" />
      <v-row>
        <v-col class="pb-0">
          <v-textarea
            v-model="publicNotes"
            :label="$t('label.publicNotes')"
            rows="3"
            filled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="can('manage_internal_notes', 'person')" class="py-0">
          <v-textarea
            v-model="internalNotes"
            :label="$t('label.internalNotes')"
            rows="3"
            filled
          />
        </v-col>
      </v-row>

      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="save()" color="primary" :disabled="!formValid">{{
            $t("button.save")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import FormValidationMixin from "@/components/mixins/FormValidationMixin";
import { mapGetters } from "vuex";
import AddressEntry from "@/components/forms/AddressEntry";

export default {
  name: "EditOrganizationWidget",
  components: { AddressEntry },
  mixins: [FormValidationMixin],

  props: {
    organization: { required: false, type: Object },
  },

  data() {
    return {
      obj: this.organization,
      name: this.organization ? this.organization.name : "",
      regNum: this.organization ? this.organization.reg_number : "",
      address: this.organization ? { ...this.organization.address } : {},
      formValid: false,
      internalNotes: this.person ? this.organization.internal_notes : "",
      publicNotes: this.person ? this.organization.public_notes : "",
    };
  },

  computed: {
    ...mapGetters(["can"]),
  },

  methods: {
    async save() {
      let data = {
        name: this.name,
        reg_number: this.regNum || null,
        address: this.address,
        internal_notes: this.internalNotes,
        public_notes: this.publicNotes,
      };
      try {
        let resp = null;
        if (this.obj) {
          resp = await axios.patch(`/api/organization/${this.obj.pk}/`, data);
        } else {
          resp = await axios.post("/api/organization/", data);
        }
        this.$emit("save", resp.data);
      } catch (error) {
        console.error("Error saving data: ", error);
      }
    },
  },
};
</script>
