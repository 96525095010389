<template>
  <router-link
    class="subdued"
    :to="{ name: 'organizationDetail', params: { organizationId: org.pk } }"
  >
    {{ org.name }}
  </router-link>
</template>

<script>
export default {
  name: "OrganizationLink",

  props: {
    org: { required: true, type: Object },
  },
};
</script>

<style scoped></style>
