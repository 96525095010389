<template>
  <div>
    <div>{{ address.street }}</div>
    <div>
      <span v-if="!separateZip">{{ address.zip }}</span> {{ address.city }}
    </div>
    <div v-if="separateZip">{{ address.zip }}</div>
    <div>{{ address.country }}</div>
  </div>
</template>

<script>
export default {
  name: "AddressWidget",

  props: {
    address: { required: true, type: Object },
    separateZip: { default: false, type: Boolean },
  },
};
</script>

<style scoped></style>
