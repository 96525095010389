<template>
  <v-speed-dial
    v-model="speedDialOpen"
    direction="right"
    transition="slide-x-transition"
  >
    <template #activator>
      <v-btn color="primary" fab small>
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-tooltip bottom v-for="item in items" :key="item.pk">
      <template #activator="{ on }">
        <v-btn
          color="primary"
          fab
          small
          elevation="0"
          v-on="on"
          @click="item.action ? item.action(item) : null"
          class="ms-1 me-0"
        >
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          <span v-else class="font-weight-black">{{
            (item.title === "__default__"
              ? item.category.title
              : item.title
            ).substr(0, 1)
          }}</span>
        </v-btn>
      </template>
      {{ item.title }}
    </v-tooltip>
  </v-speed-dial>
</template>
<script>
export default {
  name: "IconMenu",

  props: {
    icon: { required: true, type: String },
    items: { required: true, type: Array },
    row: { default: false, type: Boolean },
  },

  data() {
    return {
      speedDialOpen: false,
    };
  },
};
</script>

<style scoped lang="scss">
.menu-container {
  background: white;
}
</style>
