<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title>{{
        this.organization
          ? $t("label.editOrganization")
          : $t("label.addOrganization")
      }}</v-card-title>
      <v-card-text>
        <EditOrganizationForm :organization="organizationObj" @save="save">
          <template #buttons>
            <v-btn @click="show = false">{{ $t("button.cancel") }}</v-btn>
          </template>
        </EditOrganizationForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditOrganizationForm from "@/components/forms/EditOrganizationForm";
export default {
  name: "EditOrganizationDialog",

  components: { EditOrganizationForm },

  props: {
    value: { required: true, type: Boolean },
    organization: { required: false, type: Object },
  },

  data() {
    return {
      show: this.value,
      organizationObj: this.organization,
    };
  },

  methods: {
    save(data) {
      this.$emit("save", data);
    },
  },

  watch: {
    show() {
      this.$emit("input", this.show);
    },
    value() {
      this.show = this.value;
    },
    organization() {
      this.organizationObj = this.organization;
    },
  },
};
</script>

<style scoped></style>
