<template>
  <v-icon :color="color" :large="large">{{ icon }}</v-icon>
</template>

<script>
export default {
  name: "PersonIcon",

  props: {
    person: { required: true, type: Object },
    color: { default: "#bbbbbb", type: String },
    large: { default: false, type: Boolean },
  },

  computed: {
    icon() {
      return "mdi-account";
    },
  },
};
</script>

<style scoped></style>
