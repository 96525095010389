<template>
  <v-icon :color="color" :large="large">{{ icon }}</v-icon>
</template>

<script>
export default {
  name: "OrganizationIcon",

  props: {
    org: { required: true, type: Object },
    color: { default: "#bbbbbb", type: String },
    large: { default: false, type: Boolean },
  },

  computed: {
    icon() {
      return this.org.icon ? this.org.icon : "mdi-domain";
    },
  },
};
</script>

<style scoped></style>
