<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card-title>{{
        this.person ? $t("label.editPerson") : $t("label.addPerson")
      }}</v-card-title>
      <v-card-text>
        <EditPersonForm :person="personObj" @save="save">
          <template #buttons>
            <v-btn @click="show = false">{{ $t("button.cancel") }}</v-btn>
          </template>
        </EditPersonForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditPersonForm from "@/components/forms/EditPersonForm";
export default {
  name: "EditPersonDialog",

  components: { EditPersonForm },

  props: {
    value: { required: true, type: Boolean },
    person: { required: false, type: Object },
  },

  data() {
    return {
      show: this.value,
      personObj: this.person,
    };
  },

  methods: {
    save(data) {
      this.$emit("save", data);
    },
  },

  watch: {
    show() {
      this.$emit("input", this.show);
    },
    value() {
      this.show = this.value;
    },
    person() {
      this.personObj = this.person;
    },
  },
};
</script>

<style scoped></style>
