import Mustache from "mustache";

function preprocessObjectWithContacts(obj) {
  let contacts = [];
  obj.contacts.forEach((item) => {
    contacts.push({
      value: item.value,
      type:
        item.contact_type.name === "__default__"
          ? item.contact_type.category.name
          : item.contact_type.name,
      icon: item.contact_type.icon || item.contact_type.category.icon,
      category: item.contact_type.used_field,
      public_notes: item.public_notes,
      internal_notes: item.internal_notes,
    });
    item.contact_type.title =
      item.contact_type.name === "__default__"
        ? item.contact_type.category.name
        : item.contact_type.name;
  });
  contacts.sort((a, b) => a.type.localeCompare(b.type));
  obj.contacts_ = contacts;
  obj.emails_ = obj.contacts_.filter((item) => item.category === "email");
  obj.phones_ = obj.contacts_.filter((item) => item.category === "phone");
  return obj;
}

function contactUrl(contact) {
  if (contact.contact_type.url_template) {
    return Mustache.render(contact.contact_type.url_template, {
      value: contact.value,
    });
  }
  return "";
}

export { preprocessObjectWithContacts, contactUrl };
