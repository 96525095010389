<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t("label.address") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" order="1" class="py-0">
        <v-text-field
          v-model="address.street"
          :label="$t('label.street')"
          dense
        />
      </v-col>
      <v-col cols="12" md="8" order="2" order-md="3" class="py-0">
        <v-text-field v-model="address.city" :label="$t('label.city')" dense />
      </v-col>
      <v-col cols="12" md="4" order="3" order-md="2" class="py-0">
        <v-text-field v-model="address.zip" :label="$t('label.zip')" dense />
      </v-col>
      <v-col cols="12" order="4" class="py-0">
        <v-text-field
          v-model="address.country"
          :label="$t('label.country')"
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import isEqual from "lodash/isEqual";

export default {
  name: "AddressEntry",

  props: {
    address: { type: Object, required: true },
  },

  data() {
    return {
      localAddress: { ...this.address },
    };
  },

  watch: {
    address: {
      handler() {
        if (!isEqual(this.address, this.localAddress)) {
          this.localAddress = { ...this.address };
        }
      },
      deep: true,
    },
    localAddress: {
      handler() {
        this.$emit("update:address", this.localAddress);
      },
      deep: true,
    },
  },
};
</script>
